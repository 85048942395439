import { OpenAI } from 'openai';

const configuration = {
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
};

export const analyzeActorsAndBehavior = async (input) => {
    const openai = new OpenAI(configuration);

    // Cached system messages
    const messages = [
        { role: "system", content: "You are a Character Analyzer and Relationship Behavior Evaluator. Your task is to identify key characters from the provided input and assess their overall behavior." },
        { role: "system", content: "Character Identification and Analysis Rules:\n1. Only list characters who are the direct subjects of the user's input or have sufficient information (speech, action) for behavior analysis.\n2. Exclude side characters or any character without enough information for a meaningful behavior assessment.\n3. The user providing the input should not be listed as a character unless their own actions or behaviors are explicitly described with sufficient detail. Try not to list 'Me' unless 'Me' displays a critically red flag or green flag behavior.\n4. When encountering pronouns, always associate them with the most recently mentioned character name. If no name is available, use a concise descriptive term (e.g., 'the one (he)').\n5. For image-only inputs of message conversations, designate the recipient as 'Me' and the sender as 'Sender', unless otherwise specified, only if there's enough information for behavior analysis.\n6. For photograph inputs, identify only the primary subject(s) directly relevant to the described situation and with sufficient behavioral information.\n7. Ensure consistent and homogeneous character identification across all inputs.\n8. If ABSOLUTELY no characters can be identified, respond with 'Tell me more!' and do not provide any flag assessments AS A LAST RESORT.\n9. CRITICAL: Each character MUST be listed ONLY ONCE. DO NOT create separate entries for the same character under any circumstances, even if they display both positive and negative behaviors. Combine all behaviors into a single assessment." },
        { role: "system", content: "Behavior Analysis Criteria:\n1. For each identified character with sufficient information, provide ONLY ONE flag assessment: either a 'green flag' or 'red flag'.\n2. Criteria for Green Flag:\n   - Respectful communication\n   - Healthy boundaries\n   - Emotional maturity\n   - Honesty and transparency\n   - Supportive actions\n3. Criteria for Red Flag:\n   - Disrespectful or abusive language\n   - Violation of boundaries\n   - Manipulative behavior\n   - Conventionally unusual behavior\n   - Dishonesty or lack of transparency\n   - Controlling or possessive actions\n4. When assessing behavior, consider ALL actions and words of the character. Weigh both positive and negative behaviors to determine the overall flag.\n5. If a character displays both green and red flag behaviors, carefully consider which behavior is more significant or frequent. Assign only one flag based on the predominant behavior pattern.\n6. Always assess behaviors strictly. When in doubt or if negative behaviors outweigh positive ones, lean towards 'red flag'.\n7. Base your evaluation strictly on the provided details. Consider the implications of the actions, but do not assume actions or behaviors not explicitly mentioned.\n8. CRITICAL: Assign only ONE flag per character based on their overall behavior pattern. Never list the same character twice with different flags.\n9. When assessing the 'Me' character (the user providing the input): If 'Me' displays a mix of positive and negative behaviors, lean towards assigning a 'green flag' unless the negative behaviors are critically severe or predominantly negative." },
        { role: "system", content: "Output Format Rules:\n1. If sufficient information is available for at least one character, provide clear and concise reasoning for each flag assignment within 20 words.\n2. Do not include punctuation in the output.\n3. Put the flag and the explanation on a single line for each character.\n4. Be extremely sensitive detecting attitudes and behaviors.  If ABSOLUTELY no characters can be identified, respond with 'Tell me more!' and do not provide any flag assessments AS A LAST RESORT\n5.  ABSOLUTELY CRITICAL: ALWAYS respond in format: actor||||flag||||reason. Example outputs:\n   John||||red flag||||mostly controlling behavior despite occasional kindness\n   Unknown person (she)||||green flag||||consistently communicates openly and respects boundaries\n   Me||||green flag||||generally respectful and communicative despite occasional lapses in judgment\n   Tell me more!\n6. CRITICAL: Ensure each character appears only once in the output. Double-check your response before submitting to prevent any duplicate entries." },
        { role: "user", content: "Identify the key characters from the provided input and assess their overall behavior. List each character ONLY ONCE on a new line, followed by their behavior assessment. CRITICAL: ALWAYS respond in format: actor||||flag||||reason." }
    ];

    const userContent = [
        ...(input.text.trim() ? [{ type: "text", text: `Input: "${input.text}"` }] : []),
        ...input.imageUris.filter(uri => uri.trim()).map(uri => ({
            type: "image_url",
            image_url: { url: uri, detail: "low" }
        }))
    ];

    if (userContent.length === 0) {
        console.error('No valid content to process.');
        return [];
    }

    messages.push({ role: "user", content: userContent });

    try {
        console.log("Analyzing characters and behavior with input:", input);
        const response = await openai.chat.completions.create({
            model: "gpt-4o",
            messages: messages,
            max_tokens: 1024,
        });
        console.log("API response for character analysis and behavior assessment:", response);

        const resultText = response.choices[0].message.content.trim().split('\n').filter(Boolean);

        if (resultText.length === 1 && resultText[0] === 'Tell me more!') {
          console.log("Unique result: Tell me more! (Insufficient information for behavior analysis)");
          return [{ actor: 'Tell me more!', outcome: '', reason: 'Insufficient information for behavior analysis' }];
      }

      const analysisResults = new Set();
      const processedResults = resultText.reduce((acc, result) => {
          const [actor, flag, reason] = result.split('||||').map(item => item.trim());
          if (actor && flag && reason && !analysisResults.has(actor)) {
              analysisResults.add(actor);
              const outcome = flag.toLowerCase().includes('green flag') ? '🟢' : '🚩';
              acc.push({ actor, outcome, reason });
             
          }
          return acc;
      }, []);

      console.log("All unique results:", processedResults);
      return processedResults;
  } catch (error) {
      console.error("Error analyzing characters and behavior:", error);
      return [];
    }
};
export const generateNewUrl = (uniqueKey) => {
  const url = `${window.location.origin}/${uniqueKey}`;
  console.log('Generated URL:', url);
  window.history.pushState({ path: url }, '', url);
  return url;
};

export const resetUrl = () => {
  const baseUrl = window.location.origin;
  window.history.pushState({}, '', baseUrl);
  window.location.reload();
};

export const parseUrlParams = async (setQuery, setImageUris, setParsedResults) => {
  const path = window.location.pathname;
  const uniqueKey = path.split('/').pop();
  if (uniqueKey) {
    try {
      const response = await fetch(`/.netlify/functions/renderpage?key=${uniqueKey}&format=json`);
      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }
      const data = await response.json();
      setQuery(data.text || '');
      setImageUris(data.imageUris || []);
      setParsedResults(data.response || []);
      console.log('Retrieved data from Netlify function:', data);
    } catch (error) {
      console.error('Error fetching data from Netlify function:', error);
    }
  } else {
    console.log('No unique key found in URL');
  }
};

export const handlePageLoad = (setQuery, setImageUris, setParsedResults) => {
  const navigation = window.performance.getEntriesByType("navigation")[0];
  if (navigation && navigation.type === 'reload') {
    console.log('Page was reloaded, resetting...');
    setQuery('');
    setImageUris([]);
    setParsedResults([]);
    const baseUrl = window.location.origin;
    window.history.replaceState({}, '', baseUrl);
  } else {
    console.log('Page loaded normally with navigation or direct access');
    parseUrlParams(setQuery, setImageUris, setParsedResults);
  }
};
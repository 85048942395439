import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set } from 'firebase/database';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: "https://redflagai-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "redflagai",
  storageBucket: "redflagai.appspot.com",
  messagingSenderId: "723419395192",
  appId: "1:723419395192:web:9dfca65215efcc1d74698d",
  measurementId: "G-3TXV95K607"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const normalizeKeyword = (keyword) => {
  return keyword.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
};

const extractKeywords = (text, imageUris, analysisResults) => {
  let inputKeywords = new Set();
  let analysisKeywords = new Set();

  // Process text input
  if (text && text.trim().length > 0) {
    const textWords = text.split(/\s+/).map(normalizeKeyword).filter(word => word.length > 2);
    inputKeywords = new Set(textWords.slice(0, 9)); // Cap input keywords at 9
  }

  // Process image URIs
  if (imageUris && imageUris.length > 0) {
    inputKeywords.add('image');
  }

  // Process analysis results
  if (analysisResults && analysisResults.length > 0) {
    analysisResults.forEach(result => {
      if (result.actor !== 'Tell me more!') {
        analysisKeywords.add(normalizeKeyword(result.actor));
        analysisKeywords.add(normalizeKeyword(result.outcome === '🟢' ? 'green' : 'red'));
      }
    });
  }

  // Combine keywords in the desired order
  const allKeywords = [
    ...Array.from(inputKeywords).slice(0, 9),
    ...Array.from(analysisKeywords).slice(0, 6)
  ];

  // Create a formatted timestamp (YYYYMMDDTHHMMSS)
  const date = new Date();
  const formattedTimestamp = date.toISOString().replace(/[-:]/g, '').replace('T', '').slice(0, 12);


  // Join keywords with '-' and add the timestamp
  return `${allKeywords.join('-')}-${formattedTimestamp}`;
};

export const storeData = async (input, adviceResults = null) => {
  const uniqueKey = extractKeywords(input.text, input.imageUris, adviceResults);
  const data = {
    text: input.text,
    imageUris: input.imageUris,
    timestamp: new Date().toISOString()
  };

  if (adviceResults) {
    data.response = adviceResults;
  }

  const newEntryRef = ref(database, `advice-entries/${uniqueKey}`);
  await set(newEntryRef, data);
  console.log('Data stored successfully with key:', uniqueKey);
  return uniqueKey;
};

export const updateData = async (uniqueKey, adviceResults) => {
  const entryRef = ref(database, `advice-entries/${uniqueKey}`);
  await set(entryRef, { response: adviceResults }, { merge: true });
  console.log('Data updated successfully for key:', uniqueKey);
};
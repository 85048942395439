import { OpenAI } from 'openai';

const configuration = {
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
};

const openai = new OpenAI(configuration);

// Speech recognition function using Whisper API (maybe up to 5 minutes for now)
export const transcribeSpeech = async (audioBlob) => {
  try {
    // Convert audioBlob to ArrayBuffer
    const arrayBuffer = await audioBlob.arrayBuffer();
    
    // Ensure the arrayBuffer length is a multiple of 4
    const validLength = Math.floor(arrayBuffer.byteLength / 4) * 4;
    const trimmedArrayBuffer = arrayBuffer.slice(0, validLength);
    
    const audioData = new Float32Array(trimmedArrayBuffer);

    // Check for silence or very low audio levels
    const threshold = 0.01;
    const isSilent = audioData.every(sample => Math.abs(sample) < threshold);

    if (isSilent) {
      console.log("No speech detected in the audio.");
      return ""; // Return an empty string if no speech is detected
    }

    // Convert audioBlob to File object
    const audioFile = new File([audioBlob], 'speech.wav', { type: 'audio/wav' });

    // Make the API call to Whisper
    const transcript = await openai.audio.transcriptions.create({
      file: audioFile,
      model: "whisper-1",
    });

    console.log("Speech transcription result:", transcript);

    return transcript.text;
  } catch (error) {
    console.error("Error transcribing speech:", error);
    return "Try again!";
  }
};
import React, { useRef, useMemo, useState } from 'react';
import { Box, IconButton, Typography, Drawer, List, ListItem, ListItemText, Container, Button, Dialog, DialogTitle, DialogContent, DialogContentText, ListItemSecondaryAction, Paper } from '@mui/material';
import { FacebookShareButton, RedditShareButton, TwitterShareButton, FacebookIcon, RedditIcon, TwitterIcon } from 'react-share';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import MicIcon from '@mui/icons-material/Mic';
import { motion } from 'framer-motion';
import ImageUpload from './imageUpload';

const TrafficLightLoader = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
    {['#EF4444', '#FBBF24', '#10B981'].map((color, index) => (
      <motion.div
        key={color}
        style={{ width: '16px', height: '16px', backgroundColor: color, borderRadius: '50%' }}
        animate={{ opacity: [0.2, 1, 0.2] }}
        transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut", delay: index * 0.5 }}
      />
    ))}
  </div>
);

const separateImageUris = (imageUrl) => 
    Array.isArray(imageUrl) ? imageUrl : typeof imageUrl === 'string' ? imageUrl.split(',').map(url => url.trim()) : [];

const commonStyles = {
    fontFamily: 'Poppins, sans-serif',
    color: 'text.secondary',
};

const buttonStyles = {
    ...commonStyles,
    borderRadius: '100px',
    padding: '6px 23px',
    fontWeight: 'bold',
    fontSize: '1rem',
};

export const MainContent = React.memo(({ theme, setIsDrawerOpen, query, setQuery, handleImageUpload, imageUris, handleUserInput, resetUrl, setResults, parsedResults, results, loading, newUrl, isListening, toggleListening, logo, appTitle }) => {
    const textFieldRef = useRef(null);

    const headerContent = useMemo(() => (
        <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 260, damping: 20 }}
            style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
        >
            <motion.div
                style={{ 
                    width: '80px', 
                    height: '80px', 
                    backgroundColor: 'white', 
                    borderRadius: '50%', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center' 
                }}
                whileHover={{ rotate: 20 }}
            >
              <img 
                src={logo} 
                alt="Logo" 
                style={{ width: '57px', height: '57px' }} 
                onClick={() => {
                    setQuery('');
                    setResults([]);
                    resetUrl();
                }} 
            />
            </motion.div>
            <Typography variant="h4" sx={{ ...commonStyles, fontWeight: 'bold', color: 'text.primary', fontSize: '1.8rem'}}>
                {appTitle}
            </Typography>
        </motion.div>
    ), [logo, appTitle, setQuery, setResults, resetUrl]);

    return (
        <Box sx={{ minHeight: '100vh', background: 'linear-gradient(to bottom, #FEE2E2 0%, #DCFCE7 100%)', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: theme.spacing(3), ...commonStyles }}>
            <Container maxWidth="2xl" sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2.5, mb: 5 }}>
                    <IconButton onClick={() => setIsDrawerOpen(true)} sx={{ color: 'text.secondary' }} aria-label="Toggle history drawer">
                        <MenuIcon sx={{ fontSize: '2rem' }} />
                    </IconButton>
                    {headerContent}
                    <Box sx={{ width: '24px' }} />
                </Box>

                <Paper elevation={3} sx={{ p: 2.5, mb: 2, backgroundColor: 'white', borderRadius: '24px'}}>
                    <Box sx={{ position: 'relative', mb: 2.5, border: '1px solid #E5E7EB', borderRadius: '12px', overflow: 'hidden' }}>
                        <Box sx={{ padding: '12px', paddingBottom: '12px' }}>
                            <TextareaAutosize
                                aria-label="empty textarea"
                                minRows={6}
                                maxRows={10}
                                placeholder="Spill the tea...🤔"
                                style={{ 
                                    width: '100%',
                                    padding: 0,
                                    fontSize: '1rem',
                                    color: 'text.primary',
                                    ...commonStyles,
                                    border: 'none',
                                    outline: 'none',
                                    resize: 'none',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                }}
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                ref={textFieldRef}
                            />
                        </Box>
                        <Box sx={{ position: 'relative', backgroundColor: 'transparent', borderTop: '1px ', padding: '4px 1px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <IconButton
                                onClick={toggleListening}
                                sx={{
                                    width: '28px',
                                    height: '28px',
                                    marginRight: '5px',
                                    padding: 0,
                                    borderRadius: '50%',
                                    backgroundColor: 'transparent',
                                    alignSelf: 'flex-end',
                                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                                }}
                            >
                                <MicIcon sx={{ color: isListening ? 'red' : 'inherit', fontSize: '20px' }} />
                            </IconButton>
                            <div style={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                <ImageUpload onImageUpload={handleImageUpload} initialImages={imageUris} />
                            </div>
                        </Box>
                    </Box>  
                    <Box sx={{ mb: 2.5, minHeight: '80px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                        {loading ? (
                            <Box sx={{ mt: 3, mb: 2, textAlign: 'center' }}>
                                <Box sx={{ transform: 'scale(1.4)' }}>
                                    <TrafficLightLoader />
                                </Box>
                            </Box>
                        ) : (
                            (parsedResults.length ? parsedResults : results).map((result, index) => (
                                <Box key={index} sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'space-between', 
                                    p: 2.3, 
                                    borderRadius: '10px',
                                    backgroundColor: result.actor === 'Tell me more!' ? '#FEF08A' : (result.outcome === '🟢' ? '#D1FAE5' : '#FEE2E2'),
                                    color: result.actor === 'Tell me more!' ? '#92400E' : (result.outcome === '🟢' ? '#065F46' : '#991B1B'),
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                }}>
                                    <Typography variant="body1" sx={{ ...commonStyles, fontSize: '1rem', fontWeight: 'bold', mr: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {result.actor}
                                    </Typography>
                                    <Typography variant="body1" sx={{ ...commonStyles, fontSize: '1rem', fontWeight: 'bold', flexShrink: 1 }}>
                                        {result.outcome}
                                    </Typography>
                                </Box>
                            ))
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                        <Button
                            variant="contained"
                            sx={{ 
                                ...buttonStyles,
                                backgroundColor: 'text.primary',
                                color: 'white',
                                '&:hover': { backgroundColor: 'text.secondary' },
                            }}
                            onClick={handleUserInput}
                        >
                            Get Verdict
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ 
                                ...buttonStyles,
                                backgroundColor: '#E5E7EB',
                                color: 'text.primary',
                                '&:hover': { backgroundColor: '#D1D5DB' },
                            }}
                            onClick={() => {
                                setQuery('');
                                setResults([]);
                                resetUrl();
                            }} 
                        >
                            Clear All
                        </Button>
                    </Box>
                </Paper>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="body1" sx={{ mt: 15, mb: 2, ...commonStyles, fontSize: '1rem' }}>
                        Share your verdict:
                    </Typography>
                    <ShareButtons shareUrl={newUrl} title="Check out my results on this app!" />
                </Box>
            </Container>
        </Box>
    );
});

export const SideDrawer = React.memo(({ isDrawerOpen, toggleDrawer, categorizeQueries, queries, handleQueryClick, truncateText, deleteQuery }) => (
    <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
            sx: {
                background: 'linear-gradient(to bottom, #FEE2E2 0%, #DCFCE7 100%)',
                width: { xs: '91.5%', sm: '320px' },
                ...commonStyles,
                padding: 2,
            },
        }}
    >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, ml: 1.5, mt: 3 }}>
            <Typography variant="h6" sx={{ ...commonStyles, fontWeight: 'bold', fontSize: '1.2rem' }}>
                History
            </Typography>
            <IconButton onClick={toggleDrawer(false)} sx={{ color: 'text.secondary' }}>
                <CloseIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
        </Box>
        <List>
            {Object.entries(categorizeQueries(queries)).map(([category, categoryQueries]) => (
                categoryQueries.length > 0 && (
                    <React.Fragment key={category}>
                        <ListItem>
                            <ListItemText 
                                primary={category} 
                                primaryTypographyProps={{ sx: { ...commonStyles, fontWeight: 'bold', fontSize: '1rem' } }} 
                            />
                        </ListItem>
                        {categoryQueries.slice().reverse().map((item, index) => (
                            <Paper 
                                elevation={1} 
                                sx={{ 
                                    mb: 1, 
                                    borderRadius: '12px', 
                                    overflow: 'hidden',
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    '&:hover': { boxShadow: '0 2px 3px rgba(0,0,0,0.1)' },
                                }}
                                key={index}
                            >
                                <ListItem button onClick={() => handleQueryClick(item)}>
                                    <ListItemText
                                        primary={truncateText(item.query || 'Image', 15)}
                                        secondary={item.timestamp ? new Date(item.timestamp).toLocaleDateString() : ''}
                                        primaryTypographyProps={{ sx: { ...commonStyles, fontSize: '0.95rem' } }}
                                        secondaryTypographyProps={{ sx: { ...commonStyles, fontSize: '0.85rem' } }}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                deleteQuery(queries.findIndex(q => q.timestamp === item.timestamp && q.query === item.query));
                                            }}
                                            sx={{ color: 'text.secondary' }}
                                        >
                                            <DeleteIcon sx={{ fontSize: '1.2rem' }} />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Paper>
                        ))}
                    </React.Fragment>
                )
            ))}
        </List>
    </Drawer>
));

export const ShareButtons = React.memo(({ shareUrl, title }) => {
    const ShareUrl = shareUrl && shareUrl.startsWith('http') ? shareUrl : window.location.origin;
    
    return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
            {[
                { platform: 'facebook', Button: FacebookShareButton, Icon: FacebookIcon },
                { platform: 'twitter', Button: TwitterShareButton, Icon: TwitterIcon },
                { platform: 'reddit', Button: RedditShareButton, Icon: RedditIcon },
            ].map(({ platform, Button, Icon }) => (
                <motion.div
                    key={platform}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    style={{
                        width: '32px',
                        height: '32px',
                        backgroundColor: 'white',
                        borderRadius: '40%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
                    }}
                >
                    <Button url={ShareUrl} title={title} quote={title} hashtag="#redflagdetector">
                        <Icon size={32} round bgStyle={{ fill: 'transparent' }} iconFillColor="#4B5563" />
                    </Button>
                </motion.div>
            ))}
        </div>
    );
});

export const QueryDialog = React.memo(({ isDialogOpen, handleCloseDialog, selectedQuery }) => {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    const handleCloseImageModal = () => {
        setSelectedImage(null);
    };

    return (
        <>
            <Dialog 
                open={isDialogOpen} 
                onClose={handleCloseDialog} 
                aria-labelledby="query-dialog-title" 
                maxWidth="sm" 
                fullWidth
                PaperProps={{ style: { borderRadius: '12px', padding: '24px' } }}
            >
                <DialogTitle id="query-dialog-title" sx={{ textAlign: 'center', fontSize: '1rem', fontWeight: 'bold', ...commonStyles }}>
                    {selectedQuery ? `${new Date(selectedQuery.timestamp).toLocaleDateString()}` : ''}
                </DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {selectedQuery ? (
                        <>
                            <DialogContentText sx={{ whiteSpace: 'pre-wrap', fontSize: '0.9rem', ...commonStyles, mb: 2 }}>
                                {selectedQuery.query}
                            </DialogContentText>
                            {(selectedQuery.imageUris?.length > 0 || selectedQuery.imageUrl) && (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 3, mb: 3 }}>
                                    {(selectedQuery.imageUris || separateImageUris(selectedQuery.imageUrl)).map((imageUri, index) => (
                                        imageUri && (
                                            <img 
                                                key={index} 
                                                src={imageUri} 
                                                alt="" 
                                                style={{ 
                                                    maxWidth: '100%', 
                                                    maxHeight: '100px', 
                                                    borderRadius: '4px',
                                                    cursor: 'pointer'
                                                }} 
                                                onMouseEnter={(e) => {
                                                    e.currentTarget.style.transform = 'scale(1.05)';
                                                  }}
                                                  onMouseLeave={(e) => {
                                                    e.currentTarget.style.transform = 'scale(1)';
                                                  }}
                                                onClick={() => handleImageClick(imageUri)}
                                            />
                                        )
                                    ))}
                                </Box>
                            )}
                        </>
                    ) : (
                        <Typography sx={{ fontSize: '1rem', ...commonStyles }}>
                            There is no query selected.
                        </Typography>
                    )}
                </DialogContent>
                {selectedQuery && (
                    <Box sx={{ mt: 3, textAlign: 'center', borderTop: '1px solid rgba(0, 0, 0, 0.12)', pt: 3, px: 3 }}>
                        {selectedQuery.results.map((result, index) => (
                            <Typography key={index} sx={{ fontSize: '1rem', fontWeight: 'bold', ...commonStyles, mb: 1 }}>
                                {result.actor}: {result.outcome}
                            </Typography>
                        ))}
                    </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <Button 
                        onClick={handleCloseDialog} 
                        sx={{ 
                            ...buttonStyles,
                            backgroundColor: '#E5E7EB',
                            color: 'text.primary',
                            '&:hover': { backgroundColor: '#D1D5DB' },
                        }}
                    >
                        Close
                    </Button>
                </Box>
            </Dialog>
            <Dialog
                open={!!selectedImage}
                onClose={handleCloseImageModal}
                maxWidth="md"
                fullWidth
            >
                <DialogContent>
                    <img src={selectedImage} alt="" style={{ width: '100%', height: 'auto' }} />
                </DialogContent>
                <IconButton
                    onClick={handleCloseImageModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Dialog>
        </>
    );
});